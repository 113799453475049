<template>
  <div>
    <div class="super">
      <div class="holder">
        <SmallNav />
        <div class="properties_count">
          <img
            @click="backToRented()"
            src="../assets/images/thickbackarrow.svg"
            alt="back"
          />
          <h6 @click="backToRented()">Payment Schedule</h6>
        </div>

        <PageLoader v-if="PageLoad"></PageLoader>

        <div v-else class="container">
          <div class="schedule_holder">
            <div class="payment_header">
              <p>Property Details</p>
              <img src="../assets/images/switch-icon.svg" alt="icon" />
            </div>

            <div class="property_details">
              <div>
                <div class="details">
                  <div class="days_left">
                    <h3>{{ detailsProps.daysleft }}</h3>
                    <h6>Days Left</h6>
                  </div>
                  <div class="details_wrap">
                    <p>
                      <img
                        src="../assets/images/verified-white.svg"
                        alt="verified"
                      />
                      Verified
                    </p>
                    <h4>{{ propertyObject.state }}</h4>
                    <h6>{{ propertyObject.city }}</h6>
                  </div>
                </div>
                <div class="price_holder">
                  <div>
                    <p v-if="schedule[0]">
                      &#8358;{{ Number(schedule[0].amount).toLocaleString() }}
                    </p>
                    <small v-if="schedule[0]"
                      >{{ schedule[0].tenantmodeofpayments }} Rate</small
                    >
                  </div>
                  <div>
                    <p v-if="schedule[0]">
                      &#8358;{{
                        Number(
                          schedule[0].propertyObject.price
                        ).toLocaleString()
                      }}
                    </p>
                    <small>Per Annum</small>
                  </div>
                </div>
              </div>
            </div>

            <!-- Payment schedule -->
            <div class="payment_schedule_holder col-md-12">
              <div class="schedule_header">
                <h5>Rent Payment Schedule</h5>
                <img src="../assets/images/switch-icon.svg" alt="" />
              </div>
              <div class="schedule_period">
                <div
                  v-for="(item, index) in schedule"
                  class="color__item"
                  :key="index"
                >
                  <h6>
                    <img
                      v-if="item.status == '1'"
                      src="../assets/images/green-check-round.svg"
                      alt="paid"
                    />
                    <!-- <img v-if="item.status == '0'" src="../assets/images/red-wrong.svg" alt="outstanding">  -->
                    {{ item.duedate | moment }}
                    <span v-if="item.daysleft > 0"
                      >({{ item.daysleft }} days away)</span
                    >
                  </h6>
                  <small :class="[item.status == '1' ? 'green_pay' : '']"
                    >&#8358;{{ Number(item.amount).toLocaleString() }}</small
                  >
                </div>
              </div>
              <div class="schedule_footer">
                <h5>View All</h5>
                <div>
                  <h3 v-if="paid != ''">
                    Total Paid: &#8358;{{ Number(paid).toLocaleString() }}
                  </h3>
                  <h4 v-if="outstanding != ''">
                    Total Outstanding: &#8358;{{
                      Number(outstanding).toLocaleString()
                    }}
                  </h4>
                </div>
              </div>
            </div>
            <!-- Payment schedule -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import PageLoader from "./PageLoader.vue";
import { Service } from "../store/service";
import moment from "moment";
const Api = new Service();
export default {
  name: "PyamentSchedule",
  components: {
    SmallNav,
    PageLoader
  },
  data() {
    return {
      PageLoad: false,
      detailsProps: "",
      schedule: [] || null,
      propertyObject: [],
    //   schedule: "" || [
    //     propertyObject: {} || null
    // ],
      outstanding: "",
      paid: ""
    };
  },
  // updated() {
  //   this.fetchPropertySchdeule();
  // },
  mounted() {
    // this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.detailsProps = Api.getPropertyDetails();
    console.log("PropId", JSON.stringify(this.detailsProps.id));
    if (this.detailsProps.id === undefined || this.detailsProps.id === null) {
      this.$router.push({
        path: `/dashboard/rented`
      });
    } else {
      this.fetchPropertySchdeule();
    }
    // console.log(
    //   "Navigation type",
    //   performance.getEntriesByType("navigation")[0].type
    // );
  },
  methods: {
    backToRented() {
      this.$router.push({
        path: `/dashboard/rented`
      });
    },
    async fetchPropertySchdeule() {
      this.PageLoad = true;
      const id = this.detailsProps.id;
      // this.loader = true;
      try {
        await Api.getRequest(
          `getpaymentscheduleforpropertycurrentlyrented/${id}`
        )
          .then(res => {
            this.PageLoad = false;
            // if (res.data.error) {
            //   this.PageLoad = true;
            // }
            if (res.data.success) {
              // this.daysLeft();
              console.log("Payment schdule", res);
              this.schedule = res.data.schedule;
              this.propertyObject = res.data.schedule[0].propertyObject;
              console.log("Project data", this.propertyObject);
              const x = this.schedule.filter(
                item => parseInt(item.status) === 0
              );
              const y = this.schedule.filter(
                item => parseInt(item.status) === 1
              );
              // console.log("Staus with 0", x);
              // return this.schedule.reduce((sum, item) => sum + parseInt(item.amount), 0);
              this.outstanding = x.reduce(
                (sum, amount) => sum + parseInt(amount.amount),
                0
              );
              this.paid = y.reduce(
                (sum, amount) => sum + parseInt(amount.amount),
                0
              );
              this.schedule.map(element => {
                const rentDueDate = element.duedate;
                // console.log("rented date", rentDueDate);
                const rentdate = new Date(rentDueDate);
                const currentDate = new Date();
                const timeDiff = rentdate.getTime() - currentDate.getTime();
                const diff = timeDiff / (1000 * 3600 * 24);
                const daysDiff = Math.round(diff);
                element.daysleft = parseInt(daysDiff);
                // console.log("checkkkk", parseInt(daysDiff));
              });

              // const sum = x.reduce(function (result, item) {
              //   console.log("amointttttttt", item)
              //   return result + parseInt(item.amount);
              // }, 0);
              // console.log("Outstanding", sum);
              // this.schedule = res.data.schedule[0].propertyObject;
            }
          })
          .catch(err => {
            // console.log(err);
            this.PageLoad = true;
            // this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    }
  },
  // computed: {
  //   getdata() {
  //     return this.this.fetchPropertySchdeule();
  //   }
  // },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
    // moment: function(date) {
    //   return moment(date).format("MMMM DD YYYY, h:mm:ss a");
    // },
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$yellow: #ffb100;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
  background-color: #efefee;
}
.properties_count {
  text-align: left;
  padding: 30px 20px;
  img {
    cursor: pointer;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: $black;
    cursor: pointer;
  }
}

.schedule_holder {
  background: #ffffff;
  border-radius: 10px;
  max-width: 992px;
  width: 100%;
  margin: auto;
}

.payment_header {
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 27px;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    margin-bottom: 0;
    align-items: center;
    color: $secondary;
  }
}

.property_details {
  background: $primary;
  border: 0.2px solid $primary;
  position: relative;
}
.days_left {
  width: 67px;
  height: 67px;
  position: relative;
  left: 7%;
  bottom: -32%;
  background: $secondary;
  border-radius: 50%;
  padding-top: 18px;
  margin: 7px;
  border: 1px solid #768fe9;
  h3 {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: $primary;
    margin-bottom: 0;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    text-align: center;
    color: $primary;
  }
}

.details {
  display: grid;
  grid-template-columns: 10% 1fr;
  column-gap: 5px;
}

.details_wrap {
  text-align: left;
  color: $secondary;
  padding: 21px 21px 0px 0;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    // margin-bottom: 10px;
  }
  h4 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    // line-height: 18px;
    line-height: 28px;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
  }
}

.price_holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $secondary;
  padding: 0 27px 0 104px;
  gap: 10px;
  div:first-of-type {
    text-align: left;
  }
  div:nth-child(2) {
    text-align: left;
  }
  div {
    p {
      margin-bottom: 0;
      font-family: Gotham;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 15px;
    }
    small {
      font-family: Gotham;
      font-style: italic;
      font-weight: 500;
      font-size: 12px;
    }
  }
}

// PAYMENT SCHEDULE
.payment_schedule_holder {
  margin-top: 30px;
  margin-bottom: 27px;
  padding-bottom: 50px;
}

.schedule_header {
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 21px 7px 21px;
  h5 {
    color: $secondary;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
  }
}

.schedule_period {
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 17px;
    h6 {
      font-family: "Gotham";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: $black;
      margin-bottom: 0;
    }
    small {
      font-family: "Gotham";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: $black;
    }
  }
}

.green_pay {
  color: #2fc643 !important;
}

.schedule_footer {
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  border-radius: 0px 0px 10px 10px;
  // transform: matrix(1, 0, 0, 1, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 21px 7px 21px;
  h5 {
    color: $secondary;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
  }
  h3 {
    color: $secondary;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 0;
    text-align: left;
  }
  h4 {
    color: $secondary;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 0;
    text-align: left;
  }
}

.color__item {
  &:nth-of-type(1),
  &:nth-child(2n + 1) {
    background: linear-gradient(0deg, #efefef, #efefef),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%) !important;
  }
  &:nth-of-type(2),
  &:nth-child(2n + 2) {
    background: $secondary !important;
  }
}
// PAYMENT SCHEDULE

@media screen and (max-width: 599px) {
  .days_left {
    width: 55px;
    height: 55px;
    bottom: -32%;
    padding-top: 13px;
    h3 {
      font-size: 13px;
    }
    h6 {
      font-size: 8px;
    }
  }
  .details {
    grid-template-columns: 23% 1fr;
  }
  .details_wrap {
    h4 {
      font-size: 15px;
      line-height: 24px;
    }
    h6 {
      font-size: 12px;
    }
  }
  .price_holder {
    padding: 25px 18px 10px 18px;
    div {
      p {
        font-size: 13px;
      }
      small {
        font-size: 10px;
      }
    }
  }
  .schedule_period {
    div {
      h6 {
        font-size: 10px;
      }
      small {
        font-size: 10px;
      }
    }
  }
  .schedule_footer {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 1200px) {
  // .price_holder {
  //   padding: 0 27px 0 90px;
  // }
}
</style>
